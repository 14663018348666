import React, { useState } from "react";
import loadable from "@loadable/component";
import { Text, Container } from "@atoms";
import { PortalImage } from "@molecules";
import { m } from "framer-motion";
import classNames from "classnames";

const GrowingImage = loadable(() => import("@organisms/GrowingImage"));

const GrowingImageValues = ({ heading, values, image }) => {
  const [activeImage, setActiveImage] = useState(0);
  return (
    <GrowingImage heading={heading} image={image} values smallerSpacer darker>
      <Container variant="xl" frame>
        <div className="flex w-full flex-wrap items-start flex-gap-10">
          <div className="top-[10vh] hidden w-full md:sticky md:block md:w-1/2">
            <div className="relative">
              <div
                className={classNames("relative transition duration-300", {
                  "opacity-0": activeImage !== 0,
                })}
              >
                <PortalImage image={values[0].image} blackBackground />
              </div>
              {values.slice(1).map((v, i) => (
                <div
                  className={classNames(
                    "inset-0 z-10 transition duration-300 md:absolute",
                    {
                      "opacity-0": activeImage !== i + 1,
                    }
                  )}
                >
                  <PortalImage image={v.image} blackBackground />
                </div>
              ))}
            </div>
          </div>
          <ul className="w-full pb-[10vh] text-white md:w-1/2 md:pt-[10vh]">
            {values.map((v, i) => (
              <m.li
                onViewportEnter={() => setActiveImage(i)}
                className="mb-14 md:mb-24 md:min-h-[25vh]"
                viewport={{ margin: "-33%" }}
              >
                <div className="md:pt-[5vh]">
                  <div className="mb-4 md:hidden">
                    <PortalImage image={v.image} blackBackground />
                  </div>
                  <Text className="prose prose-h2:mb-4 prose-h2:font-magilio prose-h2:text-4xl prose-h2:font-normal prose-h2:normal-case lg:prose-h2:text-6xl">
                    {v.descriptor}
                  </Text>
                </div>
              </m.li>
            ))}
          </ul>
        </div>
      </Container>
    </GrowingImage>
  );
};

export default GrowingImageValues;
